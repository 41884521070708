
@media (min-width: 0px) and (max-width: 500px) {
  .c-hero {
    //background-repeat: no-repeat;
    //background-size: cover;
    height: 200px;
    //width: auto;
  }

  body {
  //	background-image: url("/assets/img/OHT-mobile.jpg");
  //  height: 100%;
  //  background-attachment: fixed;
  //  background-position: top;
  //  background-repeat: no-repeat;
  //  background-size: contain;
  }
}

@media (min-width: 0px) and (max-width: 1200px) {
  #home {
    grid-template-rows: auto;
  }
  .navigation {
    width: 100%;
    padding: 1rem;
      ul {
        margin-top: 2rem;
      }
    }
    .u-bg-maroon .grid-container-3 {
      width: 95%;
      padding: 1rem;
      gap: 1rem;
      grid-template-columns: 1fr auto auto;
      img {
        width: 200px;
        margin-left: -20px;
        margin-top: -20px;
      }
    }
    .timeline-card {
      padding: 1rem;
    }
    main {
      height: auto;
      overflow: auto;
      -ms-scroll-snap-type: none;
      scroll-snap-type: none;
      padding-top: 0;
    }
    .section {
      height: auto;
      scroll-snap-align: end;
    }

}



@media (min-width: 875px) and (max-width: 1000px) {
  #home {
    display: flex;
    flex-direction: column;
  }
  .hero {
    width: 100%;
   }
  .navigation {
    width: 90%;
    margin-right: 1rem;
      ul {
        width: auto;
        gap: 1rem;
      }
    }
    .u-bg-maroon .flex-column {
        margin-right: 2rem;
        flex-direction: row;
      }
    .u-bg-maroon .grid-container-3 {
      flex-direction: column;
      img {
      }
    }
    .down-arrow {
      display: none;
    }
}

@media (min-width: 501px) and (max-width: 874px) {
  #home {
    display: flex;
    flex-direction: column;
  }
  main {
    height: auto;
    overflow: auto;
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    padding-top: 0;
  }
  .section {
    height: auto;
    scroll-snap-align: end;
  }
  .hero {
    width: 100%;
    .grid-container-2 {
      display: flex;
      padding: 2rem;
      flex-direction: column;
      align-items: center;
      padding-bottom: 5rem;
    }
   }
   .navigation {
    width: 90%;
    padding: 1rem;
    margin-right: 1rem;
    .logo {
      height: 75px;
      width: 75px;
      margin: 0;
    }
    ul {
        width: 30%;
        width: auto;
        gap: 1rem;
      }
    }
    .u-bg-maroon .flex-column {
        margin-right: 0;
        flex-direction: row;
      }

    .u-bg-maroon .grid-container-3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      img {
        width: 300px;
        margin-top: -110px;
      }
    }
    .down-arrow {
      display: none;
    }
    .container {
      padding: 2rem;
    }
    .grid {
      padding-top: 1rem;
      padding-bottom: 1rem;
      flex-direction: column;
      .griditem {
        padding: 1rem;
        .number {
          line-height: 110%;
        }
      }
    }
    .flex-space .flex-center-x {
      display: none;
    }

    .scroll-swipe span {
        visibility: hidden;
        display: none;
     }
      .scroll-swipe:after {
        content: 'Swipe';
      }
    .timeline-card {
      padding: 5px 20px 0 0;

    }
    .timeline {
      display: flex;
      flex-direction: column;
      padding: 0;
      padding-right: 1rem;
      //max-width: 100%;

      .timeline-item {
        border-top: none;
        border-left: solid #B9866B 2px;
        padding: 2rem 1rem 2rem 2rem;
       }
      }
    .slick-next::before, .slick-prev::before {
      font-size: 20px;
    }
    #timeline.section .main {
      display: flex;
      flex-direction: column-reverse;
    }
    p {
      margin-bottom: 1.5rem;
    }
    .grid .griditem .number {
      margin-top: 0;
      margin-bottom: 1.5rem;
      line-height: 110%;
    }

    .slick-next::before, .slick-prev::before {
      display: none;
    }
    .slick-next:focus::before, .slick-next:hover::before, .slick-prev:focus::before, .slick-prev:hover::before {
      display: none;
    }
    .action {
      padding-bottom: .5rem;
      margin: 0;
      width: 110%;
      gap: 1rem;
      margin-top: 4rem;
      margin-left: auto;
      margin-right: auto;
      .year {
        font-size: 1rem;
        margin-top: -4rem;
      }
      .button-timeline {
        padding: 10px;
        font-size: 1rem;
      }
  }
  .c-footer {
    margin-top: 10px !important;
    .footer-section {
      flex-flow: row wrap;
      gap: 10px;
    }
  }

}



@media (min-height: 0px) and (max-height: 1033px) {
  main {
    height: auto;
    overflow: auto;
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    padding-top: 0;
  }
  .section {
    height: auto;
    scroll-snap-align: end;
  }
  #home {
    display: flex;
    flex-direction: column;
    .u-bg-maroon {
      padding-bottom: 2rem;
    }
  }
  .grid {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  #trademark .container {
    padding: 2rem;
    background: $maroon;
  }

  #timeline .container {
    padding: 2rem;

  }

  .c-footer {
    padding-bottom: 1rem;
  }
  h2 {
    font-size: 1.9rem;
  }
  .u-bg-maroon .grid-container-3 {
  //  padding: 2rem 2rem 1rem 2rem;
  }
  .container {
    height: 100%;
  }
  .timeline-card {
    padding-top: 3rem;
   }


}
@media (min-width: 0px) and (max-width: 500px) {
  body {
  	background-image: url("/assets/img/12thman-bg-mobile.jpg") !important;
    background-size: auto;
  }
  h1 {
    margin-left: 1rem;
  }
  #home {
    display: flex;
    flex-direction: column;
  }
  main {
    height: auto;
    overflow: auto;
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    padding-top: 0;
  }
  .section {
    height: auto;
    scroll-snap-align: end;
  }
  .hero {
    width: 100%;
    .grid-container-2 {
      display: flex;
      padding: 2rem;
      flex-direction: column;
      align-items: center;
      padding-bottom: 5rem;
    }
    iframe {
      max-width: 100%;
      height: auto;
    }
   }
   .navigation {
    width: 90%;
    padding: 1rem;
    margin-right: 1rem;
    .logo {
      height: 75px;
      width: 75px;
      margin: 0;
    }
    ul {
        width: 30%;
        width: auto;
        gap: 1rem;
      }
    }
    .u-bg-maroon .flex-column {
        margin-right: 0;
      }
    .u-bg-maroon .grid-container-3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      img {
        width: 300px;
        margin-top: -110px;
      }
    }
    .down-arrow {
      display: none;
    }
    .container {
      padding: 1rem;
    }
    .grid {
      padding-top: 1rem;
      padding-bottom: 1rem;
      flex-direction: column;
      margin-top: 1em;
      .griditem {
        padding: 1rem;
        display: grid;
        grid-template-columns: 25% 75%;
        gap: 1rem;
        img {
          height: auto;
          width: 100px
        }
        .number {
          font-size: 2rem;
          margin-top: 0;
          margin-bottom: 1.5rem;
          line-height: 110%;
        }
        .numberitem {
          font-size: 1rem;
        }
      }
    }
    .flex-space .flex-center-x {
      display: none;
    }
    .flex-space h3 {
      max-width: 75%;
    }
    .scroll-swipe span {
        visibility: hidden;
        display: none;
     }
      .scroll-swipe:after {
        content: 'Swipe';
      }
    .timeline-card {
      padding: 1rem .5rem 0 0;
      p {
        font-size: .9rem;
      }
      h2 {
        font-size: 3rem;
      }
    }
    .timeline {
      display: flex;
      flex-direction: column;
      padding: 0;
      padding-right: 1rem;
      //max-width: 100%;

      .timeline-item {
        border-top: none;
        border-left: solid #B9866B 2px;
        padding: 2rem 1rem 2rem 2rem;
       }
      }
    .slick-next::before, .slick-prev::before {
      font-size: 20px;
    }
    #timeline.section .main {
      display: flex;
      flex-direction: column-reverse;
    }
    p {
      margin-bottom: 1.5rem;
    }


    .slick-next::before, .slick-prev::before {
      display: none;
    }
    .slick-next:focus::before, .slick-next:hover::before, .slick-prev:focus::before, .slick-prev:hover::before {
      display: none;
    }
    .action {
      padding-bottom: .5rem;
      margin: 0;
      width: 105%;
      gap: 5px;
      margin-top: 4rem;
      margin-left: -1rem;
      margin-right: 0;
      .year {
        font-size: .85rem;
        margin-top: -4rem;
      }
      .button-timeline {
        padding: 10px;
        font-size: .9rem;
      }
  }
  .c-footer {
    margin-top: 10px !important;
    .footer-section {
      flex-flow: row wrap;
      gap: 10px;
    }
    .c-footer_submenu ul {
      flex-direction: column;
      li {
        font-size: .85rem;
      }
    }
  }

    #trademark .container {
      grid-template-rows: auto 1fr auto;
    }

}
