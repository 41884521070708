
@import "components/reset";
@import "components/variables";
@import "components/breakpoints";

@import "components/pl";
@import "components/base";
@import "components/footer";
@import "components/timeline";
@import "components/fullpage";
@import "components/slick";
@import "components/footer";
@import "components/responsive";
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.0/css/all.min.css');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Oswald:400,600,700&display=swap');
