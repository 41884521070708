body {
	font-family: "Open Sans";
	height: 100%;
	background-color: black;
	background-image: url("/assets/img/12thman-bg.jpg");
	//height: 70vh;
	//max-width: 100%;
	//width: 100vw;
	//height: 100%;
	background-attachment: fixed;
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
	}
h1, h2 {
	font-family: $oswald;
	color: $maroon;
}
h1 {
 font-size: 4.5rem;
 font-weight: bold;
 margin-bottom: -5px;
 margin-left: 3rem;
 text-transform: uppercase;
}
h2 {
 font-size: 1.9rem;
 line-height: 125%;
 text-transform: uppercase;

}
h3 {
	font-family: $open-sans;
	line-height: 135%;
	color: $white;
	font-size: .85rem;
	text-transform: uppercase;
	font-weight: bold;
}
p {
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	margin-bottom: 1.5rem;
}
p a {
	color: $blue-100;
	text-decoration: none;
	border-bottom: 1px dotted;
	&:hover, &:focus {
		color: $blue-200;
		text-decoration: none;
		border-bottom: 1px solid $blue-200;
	}
}

a.read-more {
	color: $white;
	text-decoration: none;
	//border-bottom: 1px dotted;
	font-weight: bold;
	text-transform: uppercase;
	display: inline-block;
	&:hover, &:focus {
		color: $gray-400;
		text-decoration: none;
		//border-bottom: 1px solid $gray-300;
	}
}

main {
  padding-top: 1rem;
}

.navigation {
  display: flex;
  width: 1200px;
  margin-right: auto;
  margin-left: auto;
	justify-content: space-between;
	padding: 1rem;
	ul {
		display: flex;
		justify-content: space-evenly;
		width: 30%;
		margin-top: 1rem;
		li a {
			font-weight: bold;
			text-decoration: none;
			color: $white;
			text-shadow: 2px 2px 8px $black;
			&:hover, &:focus {
				border-bottom: 1px solid;
			}
		}
	}

}
.logo {
	background-image: url("/assets/img/tamu-logo.svg");
	background-repeat: no-repeat;
	background-size: contain;
	height: 100px;
	width: 100px;
	margin: 0;
	//padding: 1rem;
	transition: 0.3s ease-in-out;
	//border-width: 3px;
	border-style: solid;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	//border-color: $white;
	//background-color: $white;
}


.c-button {
  display: inline-block;
  margin: 0;
  padding: 1rem 1.875rem;
  transition: 0.3s ease-in-out;
  border: 3px solid;
  font-family: $open-sans;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
	text-transform: uppercase;
}

.c-button_white {
  border-color: $white;
  background-color: transparent;
  color: $white;
	&:hover, &:focus {
	  background-color: $white;
	  border-color: $white;
		color: $maroon;
	}
}

a:focus {
  outline: 1px dotted #212121;
}


.hero {
    width: 1000px;
    margin: 0 auto;
		.grid-container-2 {
			display: grid;
			padding: 3rem;
			gap: 2rem;
			grid-template-columns: 50% 50%;
			h2 {
				text-transform: capitalize;
			}

		}
}

.u-bg-maroon {
	.grid-container-3  {
		width: 1000px;
		margin: 0 auto;
		display: grid;
		padding: 2rem;
		gap: 2rem;
		grid-template-columns: 150px 1fr auto;
		img {
			width: 300px;
		  margin-left: -120px;
		  margin-top: -120px;
		}
	}
	.flex-column {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		align-self: center;
	}
}




em {
	font-style: italic;
}
.view-more {
  max-width: 200px;
  margin: 0 auto;
  display: block;
  color: $white;
  text-decoration: none;
	text-transform: uppercase;
	font-size: .85rem;
	&:hover {
		color: $gray-100;
		transform: scale(1.1);
	}
}

.container {
  padding: 4rem;
	height: 100%;
}
.flex-space {
	display: flex;
	justify-content: space-between;
	.flex-center-x {
		display: flex;
		align-items: center;
		span {
			color: $white;
			font-family: "Georgia", sans-serif;
			font-style: italic;
			font-size: .85rem;
		}
		img {
			max-width: 100px;
			height: auto;
	}
 }
}





		main {
      height: 100vh;
      overflow: scroll;
      scroll-snap-type: y mandatory;
      scroll-behavior: smooth;
    }

    .section {
      height: 100vh;
      scroll-snap-align: start;
    }

    li {
      display: inline-block;
      list-style-type: none;
    }

		#timeline {
		  background-color: black;
		}
		#trademark .container {
		  background-color: $maroon;
			display: grid;
			grid-template-rows: auto 1fr 130px;
		}
		#home  {
		  display: grid;
			grid-template-rows: 1fr 1fr 1fr;
		}


		.down-arrow {
			width: 25px;
			margin: 0 auto;
			@include respond-to(sm) {
				display: none;
			}
			a {
				color: $white;
				&:hover {
					color: $gray-400;
				}
			}
		}
		#timeline .down-arrow {
		  padding-top: 1rem;
		}
