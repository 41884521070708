
  .c-footer {
    background: $maroon;
    margin: 0 auto;
    border-top: 1px solid $white;
    width: 100%;

    .footer-section {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 1200px;
      margin: 0 auto;
      padding-top: 1rem;
      img {
        width: 200px;
        height: auto;
      }
      .c-footer_social-items {
        display: flex;
        //max-width: 230px;
        // width: 100%;
        gap: .5rem;
        justify-content: center;
        align-items: center;
        //margin: 2rem auto 2rem;
        color: white;
        ul {
          display: flex;
          justify-content: space-evenly;

          li {
            list-style: none;
            margin: 0 0.375rem;
            a {
              color: $white;

              &:hover {
                color: $gray-300;
              }
            }
          }
        }
      }

    }
    .c-footer_submenu {
      background-color: $maroon;
      // padding: 0.75rem 0;
      ul {
        display: flex;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        display: flex;
        white-space: nowrap;
        @include respond-until(md) {
          //flex-direction: column;
        }
        li {
          list-style: none;
          margin: 0.5rem 1rem;
          @include respond-from(md) {
            //margin: 1rem;
          }

          a {
            font-size: 1rem;
            color: $white;
            text-decoration: none;
            border-bottom: 1px dotted;
            &:hover {
              color: $gray-300;
              text-decoration: none;
              border-bottom: 1px solid;
            }
          }
        }
     }
   }
   }
