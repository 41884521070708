/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.0/css/all.min.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Oswald:400,600,700&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 * @section Base Styles
 */
/* -- Open Sans -- */
/* -- Color Variables -- */
/* --Background Colors -- */
.u-bg-white {
  background-color: #ffffff !important; }

.u-bg-maroon {
  background-color: #500000 !important; }

.u-bg-maroon-100 {
  background-color: #732f2f !important; }

.u-bg-maroon-200 {
  background-color: #500000 !important; }

.u-bg-maroon-300 {
  background-color: #3C0000 !important; }

.u-bg-red {
  background-color: #e4002b !important; }

.u-bg-yellow {
  background-color: #fce300 !important; }

.u-bg-green {
  background-color: #3ed488 !important; }

.u-bg-gray-100 {
  background-color: #f6f8f7 !important; }

.u-bg-gray-200 {
  background-color: #eef0f0 !important; }

.u-bg-gray-300 {
  background-color: #d1d5d4 !important; }

.u-bg-gray-400 {
  background-color: #a7abaa !important; }

.u-bg-gray-500 {
  background-color: #707373 !important; }

.u-bg-gray-600 {
  background-color: #3e3e3e !important; }

.u-bg-gray-700 {
  background-color: #332c2c !important; }

.u-bg-gray-800 {
  background-color: #2b2021 !important; }

.u-bg-gray-900 {
  background-color: #241618 !important; }

.u-bg-black {
  background-color: #000000 !important; }

.u-bg-blue-100 {
  background-color: #006483 !important; }

.u-bg-blue-200 {
  background-color: #004362 !important; }

.u-bg-tan-100 {
  background-color: #f5efe6 !important; }

.u-bg-tan-200 {
  background-color: #e7ded0 !important; }

.u-bg-tan-300 {
  background-color: #d6d3c4 !important; }

/* -- Font Weights -- */
.u-font-weight-300 {
  font-weight: 300 !important; }

.u-font-weight-400 {
  font-weight: 400 !important; }

.u-font-weight-600 {
  font-weight: 600 !important; }

.u-font-weight-700 {
  font-weight: 700 !important; }

.u-font-weight-800 {
  font-weight: 800 !important; }

/* -- Font Weights, Open Sans -- */
.u-open-sans {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important; }

.u-open-sans-300 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300 !important; }

.u-open-sans-400 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important; }

.u-open-sans-600 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important; }

.u-open-sans-700 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important; }

.u-open-sans-800 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800 !important; }

/* -- Base Font Sizes -- */
/* -- Font Sizes, s-xl -- */
.u-font-size-xs {
  font-size: 1rem !important; }

.u-font-size-sm {
  font-size: 1.25rem !important; }

.u-font-size-md {
  font-size: 1.5rem !important; }

.u-font-size-lg {
  font-size: 1.75rem !important; }

.u-font-size-xl {
  font-size: 2rem !important; }

/* -- Font Colors for white or light backgrounds, check contrast https://webaim.org/resources/contrastchecker/ -- */
.u-color-maroon {
  color: #500000 !important; }

.u-color-gray-500 {
  color: #707373 !important; }

.u-color-gray-600 {
  color: #3e3e3e !important; }

.u-color-gray-700 {
  color: #332c2c !important; }

.u-color-gray-800 {
  color: #2b2021 !important; }

.u-color-gray-900 {
  color: #241618 !important; }

.u-color-black {
  color: #000000 !important; }

.u-color-blue-100 {
  color: #006483 !important; }

.u-color-blue-200 {
  color: #004362 !important; }

/* -- Font Colors for dark backgrounds, check color contrast https://webaim.org/resources/contrastchecker/ -- */
.u-color-white {
  color: #ffffff; }

.u-color-gray-100 {
  color: #f6f8f7 !important; }

.u-color-gray-200 {
  color: #eef0f0 !important; }

.u-color-gray-200 {
  color: #d1d5d4 !important; }

/* -- Text Align -- */
.u-text-align-l {
  text-align: left !important; }

.u-text-align-c {
  text-align: center !important; }

.u-text-align-r {
  text-align: right !important; }

.u-text-align-i {
  text-align: inherit !important; }

/* -- Text Transform -- */
.u-text-transform-u {
  text-transform: uppercase !important; }

.u-text-transform-l {
  text-transform: uppercase !important; }

/* -- Letter Spacing -- */
.u-letter-spacing-1 {
  letter-spacing: 1px !important; }

.u-letter-spacing-2 {
  letter-spacing: 2px !important; }

.u-letter-spacing-3 {
  letter-spacing: 3px !important; }

/* -- Borders -- */
.u-border {
  border: 1px solid !important; }

.u-border-t {
  border-top: 1px solid !important; }

.u-border-r {
  border-right: 1px solid !important; }

.u-border-b {
  border-bottom: 1px solid !important; }

.u-border-l {
  border-left: 1px solid !important; }

/* -- Padding -- */
.u-padding-sm {
  padding: 1rem !important; }

.u-padding-md {
  padding: 1.25rem !important; }

.u-padding-lg {
  padding: 1.5rem !important; }

.u-padding-xl {
  padding: 2rem !important; }

.u-padding-t-sm {
  padding-top: 1rem !important; }

.u-padding-t-md {
  padding-top: 1.25rem !important; }

.u-padding-t-lg {
  padding-top: 1.5rem !important; }

.u-padding-t-xl {
  padding-top: 2rem !important; }

.u-padding-r-sm {
  padding-right: 1rem !important; }

.u-padding-r-md {
  padding-right: 1.25rem !important; }

.u-padding-r-lg {
  padding-right: 1.5rem !important; }

.u-padding-r-xl {
  padding-right: 2rem !important; }

.u-padding-b-sm {
  padding-bottom: 1rem !important; }

.u-padding-b-md {
  padding-bottom: 1.25rem !important; }

.u-padding-b-lg {
  padding-bottom: 1.5rem !important; }

.u-padding-b-xl {
  padding-bottom: 2rem !important; }

.u-padding-l-sm {
  padding-left: 1rem !important; }

.u-padding-l-md {
  padding-left: 1.25rem !important; }

.u-padding-l-lg {
  padding-left: 1.5rem !important; }

.u-padding-l-xl {
  padding-left: 2rem !important; }

/* -- Margins -- */
.u-margin-center {
  margin-left: auto !important;
  margin-right: auto !important; }

.u-margin-xs {
  margin: .5rem !important; }

.u-margin-sm {
  margin: 1rem !important; }

.u-margin-md {
  margin: 1.25rem !important; }

.u-margin-lg {
  margin: 1.5rem !important; }

.u-margin-xl {
  margin: 2rem !important; }

.u-margin-t-xs {
  margin-top: .5rem !important; }

.u-margin-t-sm {
  margin-top: 1rem !important; }

.u-margin-t-md {
  margin-top: 1.25rem !important; }

.u-margin-t-lg {
  margin-top: 1.5rem !important; }

.u-margin-t-xl {
  margin-top: 2rem !important; }

.u-margin-r-sm {
  margin-right: 1rem !important; }

.u-margin-r-md {
  margin-right: 1.25rem !important; }

.u-margin-r-lg {
  margin-right: 1.5rem !important; }

.u-margin-r-xl {
  margin-right: 2rem !important; }

.u-margin-b-xs {
  margin-bottom: .25rem !important; }

.u-margin-b-sm {
  margin-bottom: 1rem !important; }

.u-margin-b-md {
  margin-bottom: 1.25rem !important; }

.u-margin-b-lg {
  margin-bottom: 1.5rem !important; }

.u-margin-b-xl {
  margin-bottom: 2rem !important; }

.u-margin-l-sm {
  margin-left: 1rem !important; }

.u-margin-l-md {
  margin-left: 1.25rem !important; }

.u-margin-l-lg {
  margin-left: 1.5rem !important; }

.u-margin-l-xl {
  margin-left: 2rem !important; }

/* -- Vertical Alignments -- */
.u-vertical-align-t {
  vertical-align: top !important; }

.u-vertical-align-m {
  vertical-align: middle !important; }

.u-vertical-align-b {
  vertical-align: bottom !important; }

.u-vertical-align-text-t {
  vertical-align: text-top !important; }

.u-vertical-align-text-b {
  vertical-align: text-bottom !important; }

/* -- Widths -- */
.u-width-10 {
  width: 10% !important; }

.u-width-20 {
  width: 20% !important; }

.u-width-30 {
  width: 30% !important; }

.u-width-40 {
  width: 40% !important; }

.u-width-50 {
  width: 50% !important; }

.u-width-60 {
  width: 60% !important; }

.u-width-70 {
  width: 70% !important; }

.u-width-80 {
  width: 80% !important; }

.u-width-90 {
  width: 90% !important; }

.u-width-100 {
  width: 100% !important; }

.show-for-sr {
  display: none; }

body {
  font-family: "Open Sans";
  height: 100%;
  background-color: black;
  background-image: url("/assets/img/12thman-bg.jpg");
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover; }

h1, h2 {
  font-family: "Oswald", sans-serif;
  color: #500000; }

h1 {
  font-size: 4.5rem;
  font-weight: bold;
  margin-bottom: -5px;
  margin-left: 3rem;
  text-transform: uppercase; }

h2 {
  font-size: 1.9rem;
  line-height: 125%;
  text-transform: uppercase; }

h3 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 135%;
  color: #ffffff;
  font-size: .85rem;
  text-transform: uppercase;
  font-weight: bold; }

p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem; }

p a {
  color: #006483;
  text-decoration: none;
  border-bottom: 1px dotted; }
  p a:hover, p a:focus {
    color: #004362;
    text-decoration: none;
    border-bottom: 1px solid #004362; }

a.read-more {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block; }
  a.read-more:hover, a.read-more:focus {
    color: #a7abaa;
    text-decoration: none; }

main {
  padding-top: 1rem; }

.navigation {
  display: flex;
  width: 1200px;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-between;
  padding: 1rem; }
  .navigation ul {
    display: flex;
    justify-content: space-evenly;
    width: 30%;
    margin-top: 1rem; }
    .navigation ul li a {
      font-weight: bold;
      text-decoration: none;
      color: #ffffff;
      text-shadow: 2px 2px 8px #000000; }
      .navigation ul li a:hover, .navigation ul li a:focus {
        border-bottom: 1px solid; }

.logo {
  background-image: url("/assets/img/tamu-logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  width: 100px;
  margin: 0;
  transition: 0.3s ease-in-out;
  border-style: solid;
  text-align: center;
  text-decoration: none;
  cursor: pointer; }

.c-button {
  display: inline-block;
  margin: 0;
  padding: 1rem 1.875rem;
  transition: 0.3s ease-in-out;
  border: 3px solid;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase; }

.c-button_white {
  border-color: #ffffff;
  background-color: transparent;
  color: #ffffff; }
  .c-button_white:hover, .c-button_white:focus {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #500000; }

a:focus {
  outline: 1px dotted #212121; }

.hero {
  width: 1000px;
  margin: 0 auto; }
  .hero .grid-container-2 {
    display: grid;
    padding: 3rem;
    gap: 2rem;
    grid-template-columns: 50% 50%; }
    .hero .grid-container-2 h2 {
      text-transform: capitalize; }

.u-bg-maroon .grid-container-3 {
  width: 1000px;
  margin: 0 auto;
  display: grid;
  padding: 2rem;
  gap: 2rem;
  grid-template-columns: 150px 1fr auto; }
  .u-bg-maroon .grid-container-3 img {
    width: 300px;
    margin-left: -120px;
    margin-top: -120px; }

.u-bg-maroon .flex-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: center; }

em {
  font-style: italic; }

.view-more {
  max-width: 200px;
  margin: 0 auto;
  display: block;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: .85rem; }
  .view-more:hover {
    color: #f6f8f7;
    transform: scale(1.1); }

.container {
  padding: 4rem;
  height: 100%; }

.flex-space {
  display: flex;
  justify-content: space-between; }
  .flex-space .flex-center-x {
    display: flex;
    align-items: center; }
    .flex-space .flex-center-x span {
      color: #ffffff;
      font-family: "Georgia", sans-serif;
      font-style: italic;
      font-size: .85rem; }
    .flex-space .flex-center-x img {
      max-width: 100px;
      height: auto; }

main {
  height: 100vh;
  overflow: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth; }

.section {
  height: 100vh;
  scroll-snap-align: start; }

li {
  display: inline-block;
  list-style-type: none; }

#timeline {
  background-color: black; }

#trademark .container {
  background-color: #500000;
  display: grid;
  grid-template-rows: auto 1fr 130px; }

#home {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr; }

.down-arrow {
  width: 25px;
  margin: 0 auto; }
  @media (max-width: 640px) {
    .down-arrow {
      display: none; } }
  .down-arrow a {
    color: #ffffff; }
    .down-arrow a:hover {
      color: #a7abaa; }

#timeline .down-arrow {
  padding-top: 1rem; }

.c-footer {
  background: #500000;
  margin: 0 auto;
  border-top: 1px solid #ffffff;
  width: 100%; }
  .c-footer .footer-section {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 1rem; }
    .c-footer .footer-section img {
      width: 200px;
      height: auto; }
    .c-footer .footer-section .c-footer_social-items {
      display: flex;
      gap: .5rem;
      justify-content: center;
      align-items: center;
      color: white; }
      .c-footer .footer-section .c-footer_social-items ul {
        display: flex;
        justify-content: space-evenly; }
        .c-footer .footer-section .c-footer_social-items ul li {
          list-style: none;
          margin: 0 0.375rem; }
          .c-footer .footer-section .c-footer_social-items ul li a {
            color: #ffffff; }
            .c-footer .footer-section .c-footer_social-items ul li a:hover {
              color: #d1d5d4; }
  .c-footer .c-footer_submenu {
    background-color: #500000; }
    .c-footer .c-footer_submenu ul {
      display: flex;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      white-space: nowrap; }
      .c-footer .c-footer_submenu ul li {
        list-style: none;
        margin: 0.5rem 1rem; }
        .c-footer .c-footer_submenu ul li a {
          font-size: 1rem;
          color: #ffffff;
          text-decoration: none;
          border-bottom: 1px dotted; }
          .c-footer .c-footer_submenu ul li a:hover {
            color: #d1d5d4;
            text-decoration: none;
            border-bottom: 1px solid; }

/*-- TIMELINE SECTION --*/
.action {
  display: flex;
  margin: 1rem auto;
  max-width: 600px;
  gap: 2rem; }
  .action .button-timeline {
    width: 33%;
    display: inline-block;
    font-family: 'Georgia', serif;
    color: #ffffff;
    padding: 1rem;
    transition: 0.3s ease-in-out;
    border-top: 20px solid;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    font-style: italic;
    line-height: 120%; }
  .action .year {
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-size: 1.5rem;
    line-height: 150%;
    text-transform: uppercase;
    color: white;
    display: block;
    font-style: normal;
    margin-top: -5rem;
    margin-bottom: 3rem; }
  .action .button-origin {
    border-top: 20px solid #B9866B;
    display: block; }
    .action .button-origin:hover, .action .button-origin:focus {
      color: #ffffff;
      background: #B9866B;
      transition: .5s ease; }
    .action .button-origin .year:hover, .action .button-origin .year:focus {
      color: #B9866B;
      transition: .5s ease; }
  .action .button-tradition {
    border-top: 20px solid #B3B7BA;
    display: block; }
    .action .button-tradition:hover, .action .button-tradition:focus {
      color: #ffffff;
      background: #B3B7BA;
      transition: .5s ease; }
    .action .button-tradition .year:hover, .action .button-tradition .year:focus {
      color: #B3B7BA;
      transition: .5s ease; }
  .action .button-legacy {
    border-top: 20px solid #C9A85D;
    display: block; }
    .action .button-legacy:hover, .action .button-legacy:focus {
      color: #ffffff;
      background: #C9A85D;
      transition: .5s ease; }
    .action .button-legacy .year:hover, .action .button-legacy .year:focus {
      color: #C9A85D;
      transition: .5s ease; }

.slick-next:before,
.slick-prev:before {
  font-size: 30px; }

.timeline-card {
  padding: 1.5rem;
  border-radius: 16px;
  color: #fff;
  display: flex;
  flex-direction: column;
  transition: .2s;
  padding-top: 5rem;
  margin-left: 0px; }
  .timeline-card img {
    width: 100%;
    height: auto; }
  .timeline-card h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 4rem; }
  .timeline-card h3 {
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    font-size: 1rem;
    color: white; }
  .timeline-card p {
    font-family: 'Georgia', serif;
    line-height: 1.5;
    margin-top: 1rem;
    padding-left: 1rem;
    margin-bottom: 1rem;
    color: #ffffff; }
    .timeline-card p a {
      color: #d1d5d4;
      border-bottom: 1px solid #d1d5d4;
      font-weight: bold; }
      .timeline-card p a:hover, .timeline-card p a:focus {
        color: white;
        border-bottom: solid #ffffff; }
  .timeline-card.card--origin h2 {
    color: #B9866B; }
  .timeline-card.card--origin p {
    border-left: solid #8B5A40 10px; }
  .timeline-card.card--tradition h2 {
    color: #B3B7BA; }
  .timeline-card.card--tradition p {
    border-left: solid #6A7074 10px; }
  .timeline-card.card--legacy h2 {
    color: #C9A85D; }
  .timeline-card.card--legacy p {
    border-left: solid #8E6A31 10px; }

#section2 .flex-container-3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 2rem;
  margin-bottom: 4rem; }

.scroll-swipe {
  display: inline; }

.ytp-title-channel, .ytp-channel {
  display: none !important;
  visibility: hidden !important; }

/*-- TRADEMARK SECTION --*/
.grid {
  margin-top: 5em;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .grid .griditem {
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;
    align-items: center; }
    .grid .griditem img {
      height: 125px; }
    .grid .griditem .statue-icon {
      height: 175px;
      margin-top: -2rem;
      margin-right: -2rem;
      margin-left: -2rem; }
    .grid .griditem .griditem-text {
      margin-left: 2rem; }
    .grid .griditem .number {
      font-family: 'Oswald', sans-serif;
      font-size: 3rem;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 110%; }
    .grid .griditem .numberitem {
      font-family: 'Oswald', sans-serif;
      font-weight: normal;
      text-transform: uppercase;
      font-size: 1.25rem;
      margin-top: -.5rem;
      letter-spacing: .5px;
      line-height: 1.5; }
  .grid p {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #ffffff; }

/*-- TIMELINE IN THE TRADEMARK SECTION --*/
.timeline {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  padding: 3rem;
  width: auto; }
  .timeline p {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #ffffff; }
  .timeline .timeline-item {
    border-top: solid #B9866B 2px;
    padding: 2rem; }
    .timeline .timeline-item .timeline-tick {
      color: #B9866B;
      display: block;
      font-size: 2.5rem;
      margin-top: -3.05rem; }
    .timeline .timeline-item h3 {
      font-family: 'Oswald', sans-serif;
      color: #ffffff;
      font-size: 2.5rem; }
    .timeline .timeline-item .date-subhead {
      font-family: 'Oswald', sans-serif;
      color: #B9866B;
      font-size: 1.5rem;
      text-transform: uppercase;
      margin-bottom: .5rem;
      line-height: 1.5; }
    .timeline .timeline-item p {
      margin-bottom: 1rem; }
    .timeline .timeline-item .aux-p .italic-text {
      font-style: italic; }
    .timeline .timeline-item .aux-p:before {
      font-family: "Font Awesome 5 Free";
      content: "\25B6";
      color: #B9866B;
      padding-right: .5rem; }

.grid .griditem .number {
  line-height: 1.5; }

/*!
 * fullPage 3.0.8
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */
html.fp-enabled,
.fp-enabled body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  /*Avoid flicker on slides transitions for mobile phones #336 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.fp-section {
  position: relative;
  -webkit-box-sizing: border-box;
  /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box;
  /* <=28 */
  box-sizing: border-box; }

.fp-slide {
  float: left; }

.fp-slide, .fp-slidesContainer {
  height: 100%;
  display: block; }

.fp-slides {
  z-index: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s ease-out;
  /* Safari<=6 Android<=4.3 */
  transition: all 0.3s ease-out; }

.fp-section.fp-table, .fp-slide.fp-table {
  display: table;
  table-layout: fixed;
  width: 100%; }

.fp-tableCell {
  display: table-cell;
  width: 100%;
  height: 100%; }

.fp-slidesContainer {
  float: left;
  position: relative; }

.fp-controlArrow {
  cursor: pointer;
  width: 0;
  height: 0;
  border-style: solid;
  margin-top: -38px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.fp-controlArrow.fp-next {
  border-width: 38.5px 0 38.5px 34px;
  border-color: transparent transparent transparent #fff; }

.fp-scrollable {
  overflow: hidden;
  position: relative; }

.fp-scroller {
  overflow: hidden; }

.iScrollIndicator {
  border: 0 !important; }

.fp-notransition {
  -webkit-transition: none !important;
  transition: none !important; }

#fp-nav {
  position: fixed;
  z-index: 100;
  margin-top: -32px;
  top: 50%;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0); }

#fp-nav.fp-right {
  right: 17px; }

#fp-nav.fp-left {
  left: 17px; }

.fp-slidesNav {
  position: absolute;
  z-index: 4;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: 0 !important;
  right: 0;
  margin: 0 auto !important; }

.fp-slidesNav.fp-bottom {
  bottom: 17px; }

.fp-slidesNav.fp-top {
  top: 17px; }

#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0; }

#fp-nav ul li,
.fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative; }

.fp-slidesNav ul li {
  display: inline-block; }

#fp-nav ul li a,
.fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none; }

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%; }

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 5px;
  width: 5px;
  border: 0;
  background: #ffffff;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0px 0px -5px; }

#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #ffffff;
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  display: block;
  opacity: 0;
  width: 0;
  cursor: pointer; }

#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  width: auto;
  opacity: 1; }

#fp-nav ul li .fp-tooltip.fp-right {
  right: 20px; }

#fp-nav ul li .fp-tooltip.fp-left {
  left: 20px; }

.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell {
  height: auto !important; }

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
  height: auto !important; }

/*Only display content to screen readers*/
.fp-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.c-footer {
  background: #500000;
  margin: 0 auto;
  border-top: 1px solid #ffffff;
  width: 100%; }
  .c-footer .footer-section {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 1rem; }
    .c-footer .footer-section img {
      width: 200px;
      height: auto; }
    .c-footer .footer-section .c-footer_social-items {
      display: flex;
      gap: .5rem;
      justify-content: center;
      align-items: center;
      color: white; }
      .c-footer .footer-section .c-footer_social-items ul {
        display: flex;
        justify-content: space-evenly; }
        .c-footer .footer-section .c-footer_social-items ul li {
          list-style: none;
          margin: 0 0.375rem; }
          .c-footer .footer-section .c-footer_social-items ul li a {
            color: #ffffff; }
            .c-footer .footer-section .c-footer_social-items ul li a:hover {
              color: #d1d5d4; }
  .c-footer .c-footer_submenu {
    background-color: #500000; }
    .c-footer .c-footer_submenu ul {
      display: flex;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      white-space: nowrap; }
      .c-footer .c-footer_submenu ul li {
        list-style: none;
        margin: 0.5rem 1rem; }
        .c-footer .c-footer_submenu ul li a {
          font-size: 1rem;
          color: #ffffff;
          text-decoration: none;
          border-bottom: 1px dotted; }
          .c-footer .c-footer_submenu ul li a:hover {
            color: #d1d5d4;
            text-decoration: none;
            border-bottom: 1px solid; }

@media (min-width: 0px) and (max-width: 500px) {
  .c-hero {
    height: 200px; } }

@media (min-width: 0px) and (max-width: 1200px) {
  #home {
    grid-template-rows: auto; }
  .navigation {
    width: 100%;
    padding: 1rem; }
    .navigation ul {
      margin-top: 2rem; }
  .u-bg-maroon .grid-container-3 {
    width: 95%;
    padding: 1rem;
    gap: 1rem;
    grid-template-columns: 1fr auto auto; }
    .u-bg-maroon .grid-container-3 img {
      width: 200px;
      margin-left: -20px;
      margin-top: -20px; }
  .timeline-card {
    padding: 1rem; }
  main {
    height: auto;
    overflow: auto;
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    padding-top: 0; }
  .section {
    height: auto;
    scroll-snap-align: end; } }

@media (min-width: 875px) and (max-width: 1000px) {
  #home {
    display: flex;
    flex-direction: column; }
  .hero {
    width: 100%; }
  .navigation {
    width: 90%;
    margin-right: 1rem; }
    .navigation ul {
      width: auto;
      gap: 1rem; }
  .u-bg-maroon .flex-column {
    margin-right: 2rem;
    flex-direction: row; }
  .u-bg-maroon .grid-container-3 {
    flex-direction: column; }
  .down-arrow {
    display: none; } }

@media (min-width: 501px) and (max-width: 874px) {
  #home {
    display: flex;
    flex-direction: column; }
  main {
    height: auto;
    overflow: auto;
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    padding-top: 0; }
  .section {
    height: auto;
    scroll-snap-align: end; }
  .hero {
    width: 100%; }
    .hero .grid-container-2 {
      display: flex;
      padding: 2rem;
      flex-direction: column;
      align-items: center;
      padding-bottom: 5rem; }
  .navigation {
    width: 90%;
    padding: 1rem;
    margin-right: 1rem; }
    .navigation .logo {
      height: 75px;
      width: 75px;
      margin: 0; }
    .navigation ul {
      width: 30%;
      width: auto;
      gap: 1rem; }
  .u-bg-maroon .flex-column {
    margin-right: 0;
    flex-direction: row; }
  .u-bg-maroon .grid-container-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%; }
    .u-bg-maroon .grid-container-3 img {
      width: 300px;
      margin-top: -110px; }
  .down-arrow {
    display: none; }
  .container {
    padding: 2rem; }
  .grid {
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-direction: column; }
    .grid .griditem {
      padding: 1rem; }
      .grid .griditem .number {
        line-height: 110%; }
  .flex-space .flex-center-x {
    display: none; }
  .scroll-swipe span {
    visibility: hidden;
    display: none; }
  .scroll-swipe:after {
    content: 'Swipe'; }
  .timeline-card {
    padding: 5px 20px 0 0; }
  .timeline {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-right: 1rem; }
    .timeline .timeline-item {
      border-top: none;
      border-left: solid #B9866B 2px;
      padding: 2rem 1rem 2rem 2rem; }
  .slick-next::before, .slick-prev::before {
    font-size: 20px; }
  #timeline.section .main {
    display: flex;
    flex-direction: column-reverse; }
  p {
    margin-bottom: 1.5rem; }
  .grid .griditem .number {
    margin-top: 0;
    margin-bottom: 1.5rem;
    line-height: 110%; }
  .slick-next::before, .slick-prev::before {
    display: none; }
  .slick-next:focus::before, .slick-next:hover::before, .slick-prev:focus::before, .slick-prev:hover::before {
    display: none; }
  .action {
    padding-bottom: .5rem;
    margin: 0;
    width: 110%;
    gap: 1rem;
    margin-top: 4rem;
    margin-left: auto;
    margin-right: auto; }
    .action .year {
      font-size: 1rem;
      margin-top: -4rem; }
    .action .button-timeline {
      padding: 10px;
      font-size: 1rem; }
  .c-footer {
    margin-top: 10px !important; }
    .c-footer .footer-section {
      flex-flow: row wrap;
      gap: 10px; } }

@media (min-height: 0px) and (max-height: 1033px) {
  main {
    height: auto;
    overflow: auto;
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    padding-top: 0; }
  .section {
    height: auto;
    scroll-snap-align: end; }
  #home {
    display: flex;
    flex-direction: column; }
    #home .u-bg-maroon {
      padding-bottom: 2rem; }
  .grid {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  #trademark .container {
    padding: 2rem;
    background: #500000; }
  #timeline .container {
    padding: 2rem; }
  .c-footer {
    padding-bottom: 1rem; }
  h2 {
    font-size: 1.9rem; }
  .container {
    height: 100%; }
  .timeline-card {
    padding-top: 3rem; } }

@media (min-width: 0px) and (max-width: 500px) {
  body {
    background-image: url("/assets/img/12thman-bg-mobile.jpg") !important;
    background-size: auto; }
  h1 {
    margin-left: 1rem; }
  #home {
    display: flex;
    flex-direction: column; }
  main {
    height: auto;
    overflow: auto;
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    padding-top: 0; }
  .section {
    height: auto;
    scroll-snap-align: end; }
  .hero {
    width: 100%; }
    .hero .grid-container-2 {
      display: flex;
      padding: 2rem;
      flex-direction: column;
      align-items: center;
      padding-bottom: 5rem; }
    .hero iframe {
      max-width: 100%;
      height: auto; }
  .navigation {
    width: 90%;
    padding: 1rem;
    margin-right: 1rem; }
    .navigation .logo {
      height: 75px;
      width: 75px;
      margin: 0; }
    .navigation ul {
      width: 30%;
      width: auto;
      gap: 1rem; }
  .u-bg-maroon .flex-column {
    margin-right: 0; }
  .u-bg-maroon .grid-container-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%; }
    .u-bg-maroon .grid-container-3 img {
      width: 300px;
      margin-top: -110px; }
  .down-arrow {
    display: none; }
  .container {
    padding: 1rem; }
  .grid {
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-direction: column;
    margin-top: 1em; }
    .grid .griditem {
      padding: 1rem;
      display: grid;
      grid-template-columns: 25% 75%;
      gap: 1rem; }
      .grid .griditem img {
        height: auto;
        width: 100px; }
      .grid .griditem .number {
        font-size: 2rem;
        margin-top: 0;
        margin-bottom: 1.5rem;
        line-height: 110%; }
      .grid .griditem .numberitem {
        font-size: 1rem; }
  .flex-space .flex-center-x {
    display: none; }
  .flex-space h3 {
    max-width: 75%; }
  .scroll-swipe span {
    visibility: hidden;
    display: none; }
  .scroll-swipe:after {
    content: 'Swipe'; }
  .timeline-card {
    padding: 1rem .5rem 0 0; }
    .timeline-card p {
      font-size: .9rem; }
    .timeline-card h2 {
      font-size: 3rem; }
  .timeline {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-right: 1rem; }
    .timeline .timeline-item {
      border-top: none;
      border-left: solid #B9866B 2px;
      padding: 2rem 1rem 2rem 2rem; }
  .slick-next::before, .slick-prev::before {
    font-size: 20px; }
  #timeline.section .main {
    display: flex;
    flex-direction: column-reverse; }
  p {
    margin-bottom: 1.5rem; }
  .slick-next::before, .slick-prev::before {
    display: none; }
  .slick-next:focus::before, .slick-next:hover::before, .slick-prev:focus::before, .slick-prev:hover::before {
    display: none; }
  .action {
    padding-bottom: .5rem;
    margin: 0;
    width: 105%;
    gap: 5px;
    margin-top: 4rem;
    margin-left: -1rem;
    margin-right: 0; }
    .action .year {
      font-size: .85rem;
      margin-top: -4rem; }
    .action .button-timeline {
      padding: 10px;
      font-size: .9rem; }
  .c-footer {
    margin-top: 10px !important; }
    .c-footer .footer-section {
      flex-flow: row wrap;
      gap: 10px; }
    .c-footer .c-footer_submenu ul {
      flex-direction: column; }
      .c-footer .c-footer_submenu ul li {
        font-size: .85rem; }
  #trademark .container {
    grid-template-rows: auto 1fr auto; } }
