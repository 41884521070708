/**
 * @section Base Styles
 */

 $breakpoints: (
	('sm', 640px),
	('md', 768px),
	('lg', 1024px),
	('xl', 1280px),
	('xxl', 1800px),

);

// respond-from is used when you want something to happen from a specific device width.
// respond-to only responds to specific sizes, i.e only md
// respond-until is the inverse of respond-from

@mixin respond-from($media) {
	@each $breakpoint in $breakpoints {
		 @if $media == nth($breakpoint, 1) {
			  @media (min-width: #{nth($breakpoint, 2)})  { @content; }
		 }
	}
}
@mixin respond-to($media) {
	@for $i from 1 through length($breakpoints) {
		$breakpoint: nth($breakpoints, $i);
		$name: nth($breakpoint, 1);
		$value: nth($breakpoint, 2);
 
		@if $media == $name {
			@if $i == 1 {
				@media (max-width: #{$value})  { @content; }
			} @else if $i == length($breakpoints) {
				@media (min-width: #{$value})  { @content; }
			} @else {
				$next_breakpoint: nth($breakpoints, $i + 1);
				$next_name: nth($next_breakpoint, 1);
				$next_value: nth($next_breakpoint, 2);
 
				@media (min-width: #{$value}) and (max-width: #{$next_value - 1px}) { @content; }
			}
		}
   }
}
@mixin respond-until($media) {
	@each $breakpoint in $breakpoints {
		 @if $media == nth($breakpoint, 1) {
			  @media (max-width: #{nth($breakpoint, 2)})  { @content; }
		 }
	}
}
