
/*-- TIMELINE SECTION --*/

.action {
  display: flex;
  margin: 1rem auto;
  max-width: 600px;
  gap: 2rem;

  .button-timeline {
    width: 33%;
    display: inline-block;
    font-family: 'Georgia', serif;
    color: $white;
    padding: 1rem;
    transition: 0.3s ease-in-out;
    border-top: 20px solid;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    font-style: italic;
    line-height: 120%;
  }
  .year {
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-size: 1.5rem;
    line-height: 150%;
    text-transform: uppercase;
    color: white;
    display: block;
    font-style: normal;
    margin-top: -5rem;
    margin-bottom: 3rem;
  }

  .button-origin {
    border-top: 20px solid $copper;
    display: block;
    &:hover, &:focus {
      color: $white;
      background: $copper;
      transition: .5s ease;
     }
     .year {
       &:hover, &:focus {
         color:  $copper;
         transition: .5s ease;
        }
     }
  }
  .button-tradition {
    border-top: 20px solid $silver;
    display: block;
    &:hover, &:focus {
      color: $white;
      background: $silver;
      transition: .5s ease;
     }
     .year {
       &:hover, &:focus {
         color:  $silver;
         transition: .5s ease;
        }
     }
  }
  .button-legacy {
    border-top: 20px solid $gold;
    display: block;
    &:hover, &:focus {
      color: $white;
      background: $gold;
      transition: .5s ease;
     }
     .year {
       &:hover, &:focus {
         color:  $gold;
         transition: .5s ease;
        }
     }
   }
}



.slick-next:before,
.slick-prev:before {
 font-size: 30px;
}

.timeline-card {
    padding: 1.5rem;
    border-radius: 16px;
    color: #fff;
    display: flex;
    flex-direction: column;
    transition: .2s;
    padding-top: 5rem;
    margin-left: 0px;
    img {
      width: 100%;
      height: auto;
    }
    h2 {
      font-family: 'Oswald', sans-serif;
      font-size: 4rem;
    }

    h3 {
      font-family: 'Open Sans', sans-serif;
      text-transform: uppercase;
      font-size: 1rem;
      color: white;
    }
      p {
        font-family: 'Georgia', serif;
        line-height: 1.5;
        margin-top: 1rem;
        padding-left: 1rem;
        margin-bottom: 1rem;
        color: $white;
        a {
          color: $gray-300;
          border-bottom: 1px solid $gray-300;
          font-weight: bold;
          &:hover, &:focus {
            color: white;
            border-bottom: solid $white;
          }
        }
      }
      &.card--origin {
        h2 {
          color: $copper;
        }
        p {
          border-left: solid $copper-dark 10px;
        }
      }
        &.card--tradition {
        h2 {
          color: $silver;
        }
        p {
          border-left: solid $silver-dark 10px;
        }
      }
        &.card--legacy {
        h2 {
          color:$gold;
        }
        p {
           border-left: solid $gold-dark 10px;
        }
      }
}

#section2 .flex-container-3 {
  //display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  //gap: 2rem;
  //margin: 2rem 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 2rem;
  margin-bottom: 4rem;

}




.scroll-swipe {
    display: inline;
}
.ytp-title-channel, .ytp-channel {
  display: none !important;
  visibility: hidden !important;
}
// #show-hide {
//   display: none;
//   visibility: hidden;
// }
// #show-hide.show {
//   display: block;
//   visibility: visible;
// }

/*-- TRADEMARK SECTION --*/

.grid {
  margin-top: 5em;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .griditem {
    display:flex;
    padding-left:2rem;
    padding-right:2rem;
    align-items: center;
    img {
      height:125px;
    }
    .statue-icon {
      height:175px;
      margin-top:-2rem;
      margin-right:-2rem;
      margin-left:-2rem;
    }
    .griditem-text {
      margin-left:2rem;
    }
    .number {
      font-family:'Oswald', sans-serif;
      font-size:3rem;
      font-weight:bold;
      text-transform:uppercase;
      line-height: 110%;
    }
    .numberitem {
      font-family: 'Oswald', sans-serif;
      font-weight:normal;
      text-transform:uppercase;
      font-size:1.25rem;
      margin-top:-.5rem;
      letter-spacing:.5px;
      line-height: 1.5;
    }

  }
  p {
    font-family:$open-sans;
    color: $white;
  }
}







/*-- TIMELINE IN THE TRADEMARK SECTION --*/
.timeline {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  padding: 3rem;
  width: auto;

  p {
    font-family:$open-sans;
    color: $white;
  }

  .timeline-item {
    border-top: solid $copper 2px;
    padding:2rem;
      .timeline-tick {
        color:$copper;
        display:block;
        font-size:2.5rem;
        margin-top:-3.05rem;
  }
    h3 {
      font-family:'Oswald', sans-serif;
      color:#ffffff;
      font-size:2.5rem;
    }

    .date-subhead {
      font-family:'Oswald', sans-serif;
      color:$copper;
      font-size:1.5rem;
      text-transform:uppercase;
      margin-bottom:.5rem;
      line-height: 1.5;

    }

    p {
      margin-bottom:1rem;
    }

    .aux-p {
      .italic-text {
          font-style: italic;
      }
      &:before {
        font-family: "Font Awesome 5 Free";
        content: "\25B6";
        color: $copper;
        padding-right: .5rem;
      }
    }
  }
}
.grid .griditem .number {
      line-height: 1.5;
}
